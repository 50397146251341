<template>
  <div>
    <Package />
  </div>
</template>

<script>
  import Package from '@/website/components/package'
  export default {
    components: {
      Package
    },
  }
</script>
