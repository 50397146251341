<template>
  <v-container fluid>

    <v-card>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="(item, index) in items" :key="index">
          {{ item.name }}
        </v-tab>
        <v-spacer/>
        <a v-if="logout" class="pa-3" @click="btn_logout()">
          <v-icon>mdi-logout</v-icon>
        </a>
      </v-tabs>

      <v-tabs-items v-if="tab==0">
        <Packagetab1 />
      </v-tabs-items>

      <v-tabs-items v-if="tab==1">
        <Packagetab2 />
      </v-tabs-items>
    </v-card>

  </v-container>
</template>

<script>
  import Packagetab1 from '@/website/components/packageTab1'
  import Packagetab2 from '@/website/components/packageTab2'
  export default {
    components: {
      Packagetab1,
      Packagetab2
    },
    data: () => ({
      // tab
      logout: false,
      tab: null,
      items: [
        { id: 0, name: 'ซื้อแพ็คเกจ' },
        { id: 1, name: 'ประวัติการซื้อแพ็คเกจ' },
      ],
    }),
    created () {
      var part = window.location.pathname
      if (part == '/package') { this.logout = true }
    },
    methods: {
      closedrawers() {
        this.drawers = false
      },
      btn_logout() {
        localStorage.removeItem('Branch_BranchID')
        localStorage.removeItem('Branch_StaffID')
        this.closedrawers()
        this.$router.push("/login")
      },
    }
  }
</script>
