<template>
  <v-container fluid>

    <v-row class="ma-0 pt-1">
      <v-col cols="12" class="pa-0" align="center">
        <span class="color-red-pk fn-30"><b> PACKHAI </b></span><span class="color-blue-pk fn-30"> SUBSCRIPTION </span>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols="1" class="pa-0"/>
      <v-col cols="5" class="pa-0">
        <div class="pk-15-blue"/>
      </v-col>
      <v-col cols="5" class="pa-0" align="end">
        <div class="pk-15-red"/>
      </v-col>
      <v-col cols="1" class="pa-0"/>
    </v-row>

    <v-row class="ma-0 pt-3">
      <v-col cols="12" class="pa-0" align="center">
        <span class="color-blue-pk fn-20"><b> แพ็คเกจรายเดือน </b></span>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols="12" md="4" class="py-5 px-7" align="center">
        <v-card elevation="0" max-width="280">
          <div class="pk-15-blue-2"/>
        </v-card>
        <v-card elevation="0" class="package-subscription-card" max-width="332">
          <v-card-text class="px-7">
            <v-row class="ma-0 pt-1">
              <v-col cols="6" class="pa-0" align="left">
                <span class="color-blue-pk fn-20"><b> PACKHAI </b></span>
              </v-col>
              <v-col cols="6" class="pa-0" align="end">
                <span class="color-blue-pk fn-50"><b> S </b></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" class="pa-2 bg-color-blue-pk" align="center">
              <span class="color-white fn-11"> เหมาะสำหรับ </span>
              <br/>
              <span class="color-white"><b> แม่ค้ามือใหม่ </b></span>
            </v-col>
          </v-row>
          <v-card-text class="px-7">
            <v-row class="ma-0 pt-1">
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> 500 ออเดอร์/เดือน </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดจำนวนร้านค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดจำนวนสินค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดคลังสินค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดแอดมิน </b></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" align="center">
              <span class="fn-20 color-blacks"><b> ใช้งานได้ทุกฟีเจอร์ </b></span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3">
            <v-col cols="12" align="center">
              <v-btn :elevation="1" class="pa-2 px-8 color-white" color="#1D3E74" dark @click="OPPPayment('S',999)">
                <v-icon size="18">fa-solid fa-baht-sign</v-icon>
                <span class="fn-20"><b>999</b></span><span class="fn-12 pt-1"> /เดือน</span>
              </v-btn>
            </v-col>
            <v-col cols="12" align="center" class="fn-11">
              <span class="color-red-pk pt-1"><b>แพ็คเกจรายเดือน (Exc. VAT 7%)</b></span>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" max-width="280">
          <div class="pk-15-blue-3"/>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" class="py-5 px-7" align="center">
        <v-card elevation="0" max-width="280">
          <div class="pk-15-red-2"/>
        </v-card>
        <v-card elevation="0" class="package-subscription-card" max-width="332">
          <v-card-text class="px-7">
            <v-row class="ma-0 pt-1">
              <v-col cols="6" class="pa-0" align="left">
                <span class="color-red-pk fn-20"><b> PACKHAI </b></span>
              </v-col>
              <v-col cols="6" class="pa-0" align="end">
                <span class="color-red-pk fn-50"><b> M </b></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" class="pa-2 bg-color-red-pk" align="center">
              <span class="color-white fn-11"> เหมาะสำหรับ </span>
              <br/>
              <span class="color-white"><b> แม่ค้ามือเก๋า </b></span>
            </v-col>
          </v-row>
          <v-card-text class="px-7">
            <v-row class="ma-0 pt-1">
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> 1,600 ออเดอร์/เดือน </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดจำนวนร้านค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดจำนวนสินค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดคลังสินค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดแอดมิน </b></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" align="center">
              <span class="fn-20 color-blacks"><b> ใช้งานได้ทุกฟีเจอร์ </b></span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3">
            <v-col cols="12" align="center">
              <v-btn :elevation="1" class="pa-2 px-6 color-white" color="#E32222" dark @click="OPPPayment('M',2999)">
                <v-icon size="18">fa-solid fa-baht-sign</v-icon>
                <span class="fn-20"><b>2,999</b></span><span class="fn-12 pt-1"> /เดือน</span>
              </v-btn>
            </v-col>
            <v-col cols="12" align="center" class="fn-11">
              <span class="color-blue-pk pt-1"><b>แพ็คเกจรายเดือน (Exc. VAT 7%)</b></span>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" max-width="280">
          <div class="pk-15-red-3"/>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" class="py-5 px-7" align="center">
        <v-card elevation="0" max-width="280">
          <div class="pk-15-blue-2"/>
        </v-card>
        <v-card elevation="0" class="package-subscription-card" max-width="332">
          <v-card-text class="px-7">
            <v-row class="ma-0 pt-1">
              <v-col cols="6" class="pa-0" align="left">
                <span class="color-blue-pk fn-20"><b> PACKHAI </b></span>
              </v-col>
              <v-col cols="6" class="pa-0" align="end">
                <span class="color-blue-pk fn-50"><b> L </b></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" class="pa-2 bg-color-blue-pk" align="center">
              <span class="color-white fn-11"> เหมาะสำหรับ </span>
              <br/>
              <span class="color-white"><b> แม่ค้ามือโปร </b></span>
            </v-col>
          </v-row>
          <v-card-text class="px-7">
            <v-row class="ma-0 pt-1">
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> 3,000 ออเดอร์/เดือน </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดจำนวนร้านค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดจำนวนสินค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดคลังสินค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดแอดมิน </b></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" align="center">
              <span class="fn-20 color-blacks"><b> ใช้งานได้ทุกฟีเจอร์ </b></span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3">
            <v-col cols="12" align="center">
              <v-btn :elevation="1" class="pa-2 px-6 color-white" color="#1D3E74" dark @click="OPPPayment('L',4999)">
                <v-icon size="18">fa-solid fa-baht-sign</v-icon>
                <span class="fn-20"><b>4,999</b></span><span class="fn-12 pt-1"> /เดือน</span>
              </v-btn>
            </v-col>
            <v-col cols="12" align="center" class="fn-11">
              <span class="color-red-pk pt-1"><b>แพ็คเกจรายเดือน (Exc. VAT 7%)</b></span>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" max-width="280">
          <div class="pk-15-blue-3"/>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-0 pt-10">
      <v-col cols="1" class="pa-0"/>
      <v-col cols="5" class="pa-0">
        <div class="pk-15-red-1"/>
      </v-col>
      <v-col cols="5" class="pa-0" align="end">
        <div class="pk-15-blue-1"/>
      </v-col>
      <v-col cols="1" class="pa-0"/>
    </v-row>

    <v-row class="ma-0 pt-3">
      <v-col cols="12" align="center">
        <span class="color-blue-pk fn-20"><b> แพ็คเกจรายปี </b></span>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols="12" md="4" class="py-5 px-7" align="center">
        <v-card elevation="0" max-width="280">
          <div class="pk-15-red-2"/>
        </v-card>
        <v-card elevation="0" class="package-subscription-card" max-width="332">
          <v-card-text class="px-7">
            <v-row class="ma-0 pt-1">
              <v-col cols="6" class="pa-0" align="left">
                <span class="color-red-pk fn-20"><b> PACKHAI </b></span>
              </v-col>
              <v-col cols="6" class="pa-0" align="end">
                <span class="color-red-pk fn-50"><b> XL </b></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" class="pa-2 bg-color-red-pk" align="center">
              <span class="color-white fn-11"> เหมาะสำหรับ </span>
              <br/>
              <span class="color-white"><b> ออเดอร์เยอะ หรือ องค์กรขนาดใหญ่ </b></span>
            </v-col>
          </v-row>
          <v-card-text class="px-7">
            <v-row class="ma-0 pt-1">
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดออเดอร์ (Unlimited) </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดจำนวนร้านค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดจำนวนสินค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดคลังสินค้า </b></span>
              </v-col>
              <v-col cols="12" class="pt-2" align="left">
                <i class="fa-solid fa-check color-green pr-2"></i>
                <span><b> ไม่จำกัดแอดมิน </b></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" align="center">
              <span class="fn-20 color-blacks"><b> ใช้งานได้ทุกฟีเจอร์ </b></span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3">
            <v-col cols="12" align="center">
              <v-btn :elevation="1" class="pa-2 px-3 color-white" color="#E32222" dark>
                <span class="fn-20"><b>Contact Us</b></span>
              </v-btn>
            </v-col>
            <v-col cols="12" align="center" class="fn-11">
              <span class="color-blue-pk pt-1"><b>แพ็คเกจรายเดือน (Exc. VAT 7%)</b></span>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" max-width="280">
          <div class="pk-15-red-3"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="py-5 px-7" align="center">
        <div class="pb-5">
          <img src="@/assets/line_packhai.jpg" width="40%">
        </div>
        <div class="pt-5">
          <span class="fn-20 pa-7 bg-color-blue-pk color-white" style="border-radius: 25px 0px 25px 0px;"><b> ติดต่อ  097-267-9487 </b></span>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="py-5 px-7">
        <div class="pt-5 color-blue-pk">
          <v-icon size="10" class="pb-1 pr-2 color-blue-pk">mdi-circle</v-icon>
          <span class="fn-16"><b>Order จัดหนัก จัดเต็ม ทุกวัน</b></span><br/>
        </div>

        <div class="pt-5 color-blue-pk">
          <v-icon size="10" class="pb-1 pr-2 color-blue-pk">mdi-circle</v-icon>
          <span class="fn-16"><b>ตัวช่วยคุมสต๊อกแพ็คเยอะแค่ไหน ก็ยัง<span class="color-red-pk">เป๊ะ</span> </b></span><br/>
        </div>

        <div class="pt-5 color-blue-pk">
          <v-icon size="10" class="pb-1 pr-2 color-blue-pk">mdi-circle</v-icon>
          <span class="fn-16"><b>รายงานสถิติ ทุกมิติ ที่คุณต้องรู้</b></span>
        </div>

        <div class="pt-10 color-red-pk fn-18">
          <b>กรุณา Add Line</b><br/>
          <b>เพื่อขอใบเสนอราคา LineID : packhaiwh</b><br/>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dspayment" max-width="450" persistent>
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            ช่องทาง
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            ดำเนินการ
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            สำเร็จ
          </v-stepper-step>
          <v-btn icon @click="ColseDialogPayment()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row class="ma-0 pb-3">
              <v-col cols="12" class="pa-0" align="center">
                <span class="pl-2 fn-18"> เลือกช่องทางการชำระเงิน </span>
              </v-col>
            </v-row>
            <v-row class="ma-0 pb-3">
              <v-col cols="12">
                <v-radio-group v-model="channelpayment.channel" dense hide-details>
                  <v-radio v-for="(i, index) in channelpayment.channelList" color="red" :key="index" :label="i.name" :value="i"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="end">
                <v-btn :elevation="1" color="primary" @click="NextStep2()">
                  <span> ถัดไป </span>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div v-if="channelpayment.qrImage==null">
              <v-row class="ma-0 pb-3" v-if="!channelpayment.loading">
                <v-col cols="12" class="pa-0" align="center">
                  <span class="pl-2 fn-18"> สรุปยอดชำระ </span>
                </v-col>
              </v-row>
              <v-row class="ma-0 pb-4" v-if="!channelpayment.loading">
                <v-col cols="6" class="pa-1">
                  ยอดที่ต้องชำระ
                </v-col>
                <v-col cols="6" class="pa-1" align="end">
                  {{ formatMoneyIsDecimal(channelpayment.price) }} บาท
                </v-col>
                <v-col cols="6" class="pa-1">
                  ภาษีมูลค่าเพิ่ม 7%
                </v-col>
                <v-col cols="6" class="pa-1" align="end">
                  {{ formatMoneyIsDecimal(channelpayment.vat) }} บาท
                </v-col>
                <v-col cols="6" class="pa-1" v-if="channelpayment.channel.id==1">
                  ค่าธรรมเนียม 2.8%
                </v-col>
                <v-col cols="6" class="pa-1" align="end" v-if="channelpayment.channel.id==1">
                  {{ formatMoneyIsDecimal(channelpayment.service) }} บาท
                </v-col>
                <v-col cols="6" class="pa-1">
                  ยอดชำระทั้งหมด
                </v-col>
                <v-col cols="6" class="pa-1" align="end">
                  <b>{{ formatMoneyIsDecimal(channelpayment.totalprice) }}</b> บาท
                </v-col>
              </v-row>
              <v-row class="ma-0 pb-4" v-else>
                <v-col cols="12" class="pa-1" align="center">
                  <v-progress-circular
                    :size="117"
                    :width="7"
                    color="red"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row class="ma-0 pb-3" v-if="!channelpayment.loading">
                <v-col cols="12" class="pa-0" align="center">
                  <img :src="`data:image/png;base64,${this.channelpayment.qrImage}`" width="123">
                </v-col>
              </v-row>
              <v-row class="ma-0 pb-4" v-else>
                <v-col cols="12" class="pa-1" align="center">
                  <v-progress-circular
                    :size="117"
                    :width="7"
                    color="red"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col cols="5">
                <v-btn :elevation="1" color="primary" :disabled="channelpayment.loading" outlined @click="ClearChannelPayment()">
                  <v-icon>mdi-chevron-left</v-icon>
                  <span> กลับ </span>
                </v-btn>
              </v-col>
              <v-col cols="2" align="center">
                <span v-if="channelpayment.second!=null">{{channelpayment.minute +":"+ CheckSecond(channelpayment.second)}}</span>
              </v-col>

              <v-col cols="5" align="end" v-if="channelpayment.tradeNo==null">
                <v-btn :elevation="1" color="primary" :disabled="channelpayment.loading" @click="Payment()">
                  <span> ชำระเงิน </span>
                </v-btn>
              </v-col>

              <v-col cols="5" v-else>
                <v-btn :elevation="1" color="primary" :loading="this.channelpayment.loadingCh" @click="PaymentCheckStatus()">
                  <span> ตรวจสอบสถานะ </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-row class="ma-0 pb-3">
              <v-col cols="12" align="center">
                <v-icon v-if="channelpayment.issuccess" size="130" color="success">mdi-checkbox-marked-circle-outline</v-icon>
                <v-icon v-else size="130" color="red">mdi-close-circle-outline</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="end">
                <v-btn :elevation="1" color="primary" @click="ColseDialogPayment()">
                  <span> ปิดหน้าต่าง <span v-if="iterations!=null">({{iterations}})</span> </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>

  </v-container>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import { SendError } from '@/website/global_alert'
  import { branchService_dotnet } from '@/website/global'
  import { formatMoneyIsDecimal } from '@/website/global_function'
  export default {
    data: () => ({
      // ช่องทางจ่ายเงิน
      subscriptionSize: null,
      step: 1,
      iterations: null,
      interval: null,
      dspayment: false,
      channelpayment: {
        loading: false,
        loadingCh: false,
        size: null,
        price: 0,
        vat: 0,
        service: 0,
        totalprice: 0,
        issuccess: false,
        minute: null,
        second: null,
        interval: null,
        qrImage: null,
        redirectUrl: null,
        tradeNo: null,
        channel: { id: 0, name: "QR PromptPay" },
        channelList: [
          { id: 0, name: "QR PromptPay" },
          { id: 1, name: "บัตรเครดิต/เดบิต" },
          // { id: 2, name: "โอนเงินผ่านบัญชีธนาคาร" }
        ],
      },
    }),
    watch: {
      step(){
        if (this.step==3){
          clearInterval(this.interval)
          this.start()
        }
      }
    },
    created() {
      this.GetSubscriptionSize()
    },
    methods: {
      async GetSubscriptionSize() {
        try {
          let response = await axios.post(branchService_dotnet+'Subscription/get-subscription-size', {
            "BranchId": localStorage.getItem('Branch_BranchID')
          }, {headers: header_token})
          if (response.status==200){
            if (response.data.status && response.data.message==null){
              this.subscriptionSize = response.data.size
            } else {
              this.SendError('warehouse', //platform
                             'Subscription', //fromPath
                             'get-subscription-size', //fromApi
                             localStorage.getItem('Branch_BranchID'), //branchId
                             null, //shopId
                             null, //orderId
                             response.data.description, //logInfo
                             response.data.message)
            }
          } else {
            this.SendError('warehouse', //platform
                           'Subscription', //fromPath
                           'get-subscription-size', //fromApi
                           localStorage.getItem('Branch_BranchID'), //branchId
                           null, //shopId
                           null, //orderId
                           null, //logInfo
                           null)
          }
        } catch (e) {
          this.SendError('warehouse', //platform
                         'subscription/package', //fromPath
                         branchService_dotnet+'Subscription/get-subscription-size', //fromApi
                         localStorage.getItem('Branch_BranchID'), //branchId
                         null, //shopId
                         null, //orderId
                         e, //logInfo
                         null)
        }
      },
      async PaymentCheckStatus() {
        this.channelpayment.loadingCh = true
        let response = await axios.post(branchService_dotnet+'Subscription/payment-status', {
          "tradeNo": this.channelpayment.tradeNo
        }, {headers: header_token})
        if (response.status == 200){
          this.channelpayment.loadingCh = false
          this.channelpayment.issuccess = true
          if (response.data){
            this.step = 3
          }
        }
      },
      qrstart() {
        this.channelpayment.minute = 4
        this.channelpayment.second = 59
        this.channelpayment.interval = setInterval(this.qrfoo, 1000);
      },
      qrfoo() {
          this.channelpayment.second--
          if (this.channelpayment.second < 0){
            this.channelpayment.second = 59
            if (this.channelpayment.minute == 0){
              clearInterval(this.channelpayment.interval);
              this.channelpayment.minute = null
              this.channelpayment.second = null
              this.channelpayment.qrImage = null
            }
            this.channelpayment.minute--
          }
      },
      async Payment(){
        // clearInterval(this.channelpayment.interval);
        this.channelpayment.loading = true
        var size = this.channelpayment.size
        var order = 0
        if (size=="S"){ order=500 }
        else if (size=="M"){ order=1600 }
        else if (size=="L"){ order=3000 }

        var data = {
          Id: 0,
          BranchId: localStorage.getItem('Branch_BranchID'),
          Size: size,
          Price: this.channelpayment.price,
          Vat: this.channelpayment.vat,
          Service: this.channelpayment.service,
          TotalPrice: this.channelpayment.totalprice,
          AddDate: 30,
          AddOrder: order,
          ChannelPayment: this.channelpayment.channel.id,
        }

        if (data.BranchId!=null && data.BranchId!='null'){
          let response = await axios.post(branchService_dotnet+'Subscription/payment-create', {
            "BranchSubscriptionHistory": data
          }, {headers: header_token})
          if (response.status == 200){
            if (response.data.status == 200){
              this.channelpayment.loading = false
              this.channelpayment.tradeNo = response.data.tradeNo
              if (data.ChannelPayment==0) {
                this.channelpayment.qrImage = response.data.qrImage
              } else if (data.ChannelPayment==1) {
                this.channelpayment.redirectUrl = response.data.redirectUrl
                window.open(this.channelpayment.redirectUrl);
              }
              // this.qrstart()
            }
          }
        }
      },
      NextStep2(){
        this.channelpayment.loading = false
        this.channelpayment.qrImage = null
        this.step = 2

        var price = this.channelpayment.price
        var vat = price * 0.07
        var service = 0
        if (this.channelpayment.channel.id==1){
          service = (price + vat) * 0.028
          this.channelpayment.totalprice = (price + vat) + service
        } else {
          this.channelpayment.totalprice = price + vat
        }
        this.channelpayment.service = service
        this.channelpayment.vat = vat
      },
      ColseDialogPayment(){
        this.dspayment = false
        if (this.step==3){
          if (this.$router.currentRoute.path == "/package"){ this.$router.push('/subscription/package') }
          else { window.location.reload(); }
        }
        this.step = 1
      },
      start() {
            this.iterations = 5;
            this.interval = setInterval(this.foo, 1000);
      },
      foo() {
          this.iterations--;
          if (this.iterations < 0){
            clearInterval(this.interval);
            this.iterations = null
            if (this.step==3)
              this.ColseDialogPayment()
          }
      },
      ClearChannelPayment(){
        this.channelpayment.issuccess = false
        this.channelpayment.tradeNo = null
        this.channelpayment.qrImage = null
        this.step = 1
      },
      OPPPayment(size,price){
        if (this.subscriptionSize!="XL"){
          this.ClearChannelPayment()
          this.channelpayment.size = size
          this.channelpayment.price = price
          this.channelpayment.channel = this.channelpayment.channelList[0]
          this.dspayment = true
        }
      },
      CheckSecond(second){
        var data = null
        data = second.toString()
        if (data.length==1) {
          data = "0"+data
        }
        return data
      },
      formatMoneyIsDecimal,
      SendError,
    }
  }
</script>
