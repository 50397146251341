<template>
  <v-container fluid>
    <loading :loading="loading" />

    <v-card elevation="1">
      <v-card-text>
        <!-- PC hidden-xs-only -->
        <v-data-table
          v-if="!loading"
          :headers="header"
          :items="dataset"
          :items-per-page="9999999999"
          hide-default-footer
          class="packhai-border-table hidden-xs-only"
        >
          <template v-slot:item.createdDatetime="{ item }">
            {{ formatDatetime(item.createdDatetime) }}
          </template>
          <template v-slot:item.price="{ item }">
            <v-row class="ma-0">
              <v-col cols="12" class="px-0" align="end">
                {{ formatMoneyIsDecimal(item.price) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.vat="{ item }">
            <v-row class="ma-0">
              <v-col cols="12" class="px-0" align="end">
                {{ formatMoneyIsDecimal(item.vat) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.service="{ item }">
            <v-row class="ma-0">
              <v-col cols="12" class="px-0" align="end">
                {{ formatMoneyIsDecimal(item.service) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.totalPrice="{ item }">
            <v-row class="ma-0">
              <v-col cols="12" class="px-0" align="end">
                {{ formatMoneyIsDecimal(item.totalPrice) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.addOrder="{ item }">
            {{ formatMoney(item.addOrder) }}
          </template>
          <template v-slot:item.channelPayment="{ item }">
            <span v-if="item.channelPayment==0"> QR PromptPay </span>
            <span v-else-if="item.channelPayment==1"> บัตรเครดิต/เดบิต </span>
            <span v-else-if="item.channelPayment==2"> โอนเงินผ่านบัญชีธนาคาร </span>
          </template>
          <template v-slot:item.isPayment="{ item }">
            <span v-if="item.isPayment" class="color-green"><v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon> สำเร็จ </span>
            <span v-else class="color-red"><v-icon color="red">mdi-close-circle-outline</v-icon> ไม่สำเร็จ </span>
          </template>
          <template v-slot:item.completeTime="{ item }">
            {{ formatDatetime(item.completeTime) }}
          </template>
        </v-data-table>

        <!-- Mobile hidden-sm-and-up -->
        <v-data-iterator
          :items="dataset"
          :items-per-page="9999999999"
          hide-default-header
          hide-default-footer
          class="hidden-sm-and-up"
        >
          <template v-slot:default="props">
            <v-row class="ma-0">
              <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
                <v-card>
                  <v-row class="packhai-showproduct-3 pa-2 pb-1">
                    <v-col cols="6" class="px-0 pt-1">
                      {{ formatDatetime(item.createdDatetime) }}
                    </v-col>
                    <v-col cols="6" class="text-right px-0 pt-1">
                      {{ item.size }}
                    </v-col>
                  </v-row>
                  <v-row class="packhai-showproduct-3 pa-2 pb-1 pt-3">
                    <v-col cols="12" class="text-center px-0">
                      <v-chip v-if="item.isPayment" color="success"><v-icon class="pr-1">mdi-checkbox-marked-circle-outline</v-icon> สำเร็จ </v-chip>
                      <v-chip v-else color="error"><v-icon class="pr-1">mdi-close-circle-outline</v-icon> ไม่สำเร็จ </v-chip>
                    </v-col>
                  </v-row>
                  <v-row class="packhai-showproduct-3 pa-2 pb-1">
                    <v-col cols="12" class="text-center px-0">
                      {{ formatDatetime(item.completeTime) }}
                    </v-col>
                  </v-row>
                  <v-row class="packhai-showproduct-3 pa-2 pb-1 tp-0">
                    <v-col cols="12" class="text-right px-0 pt-1">
                      เพิ่มวัน: {{ item.addDate }}
                    </v-col>
                  </v-row>
                  <v-row class="packhai-showproduct-3 pa-2 pt-0">
                    <v-col cols="6" class="px-0 pt-1">
                      <span v-if="item.channelPayment==0"> QR PromptPay </span>
                      <span v-else-if="item.channelPayment==1"> บัตรเครดิต/เดบิต </span>
                      <span v-else-if="item.channelPayment==2"> โอนเงินผ่านบัญชีธนาคาร </span>
                    </v-col>
                    <v-col cols="6" class="text-right px-0 pt-1">
                      เพิ่มออเดอร์: {{ formatMoney(item.addOrder) }}
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row class="packhai-showproduct-3 py-2">
                    <v-col cols="3" class="text-center pa-0">
                      <b>ราคา</b>
                    </v-col>
                    <v-col cols="3" class="text-center pa-0">
                      <b>ภาษี</b>
                    </v-col>
                    <v-col cols="3" class="text-center pa-0">
                      <b>ธรรมเนียม</b>
                    </v-col>
                    <v-col cols="3" class="text-center pa-0">
                      <b>ทั้งหมด</b>
                    </v-col>
                    <v-col cols="3" class="text-center pa-2">
                      {{ formatMoneyIsDecimal(item.price) }}
                    </v-col>
                    <v-col cols="3" class="text-center pa-2">
                      {{ formatMoneyIsDecimal(item.vat) }}
                    </v-col>
                    <v-col cols="3" class="text-center pa-2">
                      {{ formatMoneyIsDecimal(item.service) }}
                    </v-col>
                    <v-col cols="3" class="text-center pa-2">
                      {{ formatMoneyIsDecimal(item.totalPrice) }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

        <!-- เปลี่ยนหน้า -->
        <v-row v-if="pageLength>1 && dataset.length>0" class="text-center pa-2 ma-0" @click="changePage()">
          <v-col cols="12" class="pa-0">
            <v-pagination
              v-model="page"
              :length="pageLength"
              :total-visible="pageTotalVisible"
              color="info"
            ></v-pagination>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { branchService_dotnet } from '@/website/global'
  import { formatMoneyIsDecimal, formatDatetime, formatMoney } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      loading: false,
      dataset: [],
      header: [
        { align: 'center', text: 'วันที่ทำรายการ', value: 'createdDatetime', sortable: true, width: '150px' },
        { align: 'center', text: 'แพ็คเกจ', value: 'size', sortable: false, width: '80px' },
        { align: 'center', text: 'ราคา', value: 'price', sortable: false, width: '80px' },
        { align: 'center', text: 'ภาษี 7%', value: 'vat', sortable: false, width: '80px' },
        { align: 'center', text: 'ค่าธรรมเนียม 2.8%', value: 'service', sortable: false, width: '80px' },
        { align: 'center', text: 'รวมทั้งหมด', value: 'totalPrice', sortable: false, width: '80px' },
        { align: 'center', text: 'เพิ่มวัน', value: 'addDate', sortable: false, width: '80px' },
        { align: 'center', text: 'เพิ่มออเดอร์', value: 'addOrder', sortable: false, width: '80px' },
        { align: 'center', text: 'ช่องทาง', value: 'channelPayment', sortable: false, width: '80px' },
        { align: 'center', text: 'ชำระเงิน', value: 'isPayment', sortable: false, width: '80px' },
        { align: 'center', text: 'วันที่ชำระ', value: 'completeTime', sortable: false, width: '80px' },
      ],

      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 50,
      offsets: 0,
    }),
    watch: {

    },
    async created() {
      await this.GetBranchSubscriptionHistory(this.limits,this.offsets,this.page)
    },
    methods: {
      async changePage() {
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          var offsets = (this.page*this.limits)-this.limits
          this.GetBranchSubscriptionHistory(this.limits,offsets,this.page)
          this.pageTemp = this.page
        }
      },
      async GetBranchSubscriptionHistory (limits,offsets,page){
        this.loading = true
        let response = await axios.post(branchService_dotnet+'Subscription/get-subscriptionhistory', {
          "branchId": localStorage.getItem('Branch_BranchID'),
          "skip": offsets,
          "take": limits,
        },{headers: header_token})
        if (response.status==200){
          this.loading = false
          this.page = page
          this.offsets = offsets
          this.dataset = response.data.resultData

          this.pageLength =  (Math.ceil(response.data.resultCount/limits))
          if (this.pageLength<=0){this.pageLength=1}
        }
      },
      formatMoneyIsDecimal,
      formatDatetime,
      formatMoney,
    }
  }
</script>
